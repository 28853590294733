export default {
    common: {
        apartments: 'Apartamentos',
        forRent: 'En Renta',
        relatedLinks: 'Enlaces Relacionados',
        hotleadcomments: 'Me gustaría pedir una pregunta sobre ',
        lookingForQuestion: '¿No encontró lo que buscaba?',
        usQuestion: 'Háganos una pregunta',
        any: 'Alguno',
        noMin: 'Sin mín',
        noMax: 'Sin máx',
        baths: 'Baño',
        none: 'Ninguna',
        marketOverview: 'Visión general del mercado',
        saveSearchLink: 'Guarde esta búsqueda',
        saveSearchDesc: 'para recibir correos electrónicos cuando haya nuevos listados para',
        homesForSale: 'Casas en Venta',
        newHomes: 'Casas Nuevas',
        openHouses: 'Casas Abiertas',
        median: 'Mediana',
        nearby: 'Bienes Raíces y Casas En Venta Cerca de',
        nearbyRent: 'Apartamentos y Casas En Alquiler Cerca de',
        zipCodes: 'Códigos Zip',
        neighborhoods: 'Barrios',
        nNeighborhoods: 'Explorar vecindarios',
        cities: 'Ciudades',
        counties: 'Condados',
        showMore: 'Mostrar Más',
        showLess: 'Mostrar Menos',
        contactUs: 'Contactanos',
        search: 'Buscar',
        seeNearBy: 'Ver más casas cercanas',
        realEstate: 'Bienes Raíces',
        list: 'Lista',
        view: 'Ver',
        schools: 'escuelas',
        expandSearch: 'Ampliar búsqueda',
        backToMySearch: 'Volver a mi búsqueda',
        satellite: 'Satélite',
        highSchool: 'Escuela secundaria',
        middleSchool: 'Escuela intermedia',
        preK: 'Pre-K y Elemental',
        public: 'Pública',
        charter: 'Carta',
        private: 'Privada',
        tourHomes: 'Tour de casas en',
        name: 'Nombre completo',
        email: 'Email',
        price: 'Precio',
        phone: 'Número de teléfono',
        comments: 'Comentarios o preguntas',
        buySellComments: '¿Cómo podemos ayudarte?',
        writeMsg: 'Escribir mensaje',
        optional: 'Opcional',
        zipCode: 'Código postal',
        MobilePhone: 'Número de Teléfono',
        ojoLeadFormDisclaimer:
            'Te llamaremos dentro del siguiente horario comercial. Haga clic para dar su consentimiento para recibir mensajes de texto, llamadas (directas, de marcación automática o pregrabadas, incluso si están en la lista de No llamar) y / o correos electrónicos de Movoto o nuestro socio. No se requiere consentimiento para recibir servicios de bienes raíces.',
        leadFormDisclaimer:
            'Lo llamaremos dentro de la próxima hora laboral. Haga clic para aceptar recibir mensajes de texto, llamadas (directas, automáticas o pregrabadas incluso si están en la lista de No llamar) y / o correos electrónicos de Movoto o nuestro socio. No se requiere consentimiento para recibir servicios inmobiliarios.',
        noCTALeadFormDisclaimer:
            'Lo llamaremos dentro de la próxima hora laboral. Proceda a aceptar recibir mensajes de texto, llamadas (directas, automáticas o pregrabadas incluso si están en la lista de No llamar) y / o correos electrónicos de Movoto o nuestro socio. No se requiere consentimiento para recibir servicios inmobiliarios.',
        leadFormNHSDisclaimer: 'Está creando una cuenta Movoto y acepta nuestros Términos de uso y Política de privacidad.',
        scheduleTour: 'Programe un recorrido',
        comment: 'Hola, estoy interesado en visitar casas en $city con un agente inmobiliario local de primer nivel.',
        comment: 'Hola, estoy interesado en visitar casas en $city con $agentName.',
        searchDisclaimer: 'Los listados con este icono son © $year de',
        leadIDDisclaimer:
            "Al enviar su información, usted acepta que Movoto pueda compartir su información con su red de profesionales de bienes raíces e hipotecas (<a class='text-dotted' href='https://www.movoto.com/about/terms-of-use/#affiliates' target='_blank'>afiliados</a>) (o a través de sus agentes) y acepta recibir llamadas y mensajes de texto de mercadotecnia relacionados con su pedido (incluso a través del sistema de marcación telefónica automática, o tecnología de voz artificial o pregrabada) de Movoto o afiliados al número que proporcionó, incluso si su número aparece en el registro federal, estatal o corporativo de “llamadas prohibidas”. Además, acepta que dichos afiliados puedan compartir información acerca del estatus de su préstamo hipotecario con Movoto. No se requiere consentimiento para la compra.",
        disclaimers: 'Renuncias',
        there: 'Existen',
        schoolsIn: 'escuelas en',
        ratingsProvided: 'Calificaciones proporcionadas por',
        seeAll: 'Ver todo',
        localAgent: 'con un agente inmobiliario local',
        noHomes: 'No hay casas en esta área',
        legend: 'Leyenda',
        defaultSearchText: 'Current Location',
        notRelevantResult: '¿No encontró lo <br> que buscaba?',
        accessibilityRequestDisclaimer:
            'Movoto se compromete a garantizar la accesibilidad para las personas con discapacidad. Trabajamos continuamente para mejorar la accesibilidad de nuestra experiencia web para todos. Agradecemos sus comentarios y solicitudes de alojamiento, envíelos ',
        popularSearches: 'Búsquedas populares',
        featuredCities: 'Ciudades Destacadas',
        nearMe: 'cerca de mí',
        singleFamilyForSale: 'Viviendas unifamiliares en venta',
        condoAndTownhouses: 'Condominios / casas adosadas en venta',
        condoForSale: 'Condominios en venta',
        realEstateWithPool: 'Bienes Raíces con Piscina',
        priceReducedHomesForSale: 'Casas a Precio Reducido en Venta',
        newListingsForSale: 'Nuevos listados a la venta',
        newConstructionForSale: 'Casas de obra nueva en venta',
        enquiryTypeError: 'Por favor seleccione una opción',
        rentals: 'Alquileres',
        propertyRent: 'Alquiler de propiedades',
        seoLinksTitleTooltip: '$displayName, $stateCode Casas n Venta y Bienes Raíces',
        seoLinksTitleTooltipRent: 'Casas en Alquiler en $displayName, $stateCode',
        seoLinksTitleTooltipShort: '$displayName, $stateCode Casas n Venta',
        seoLinksTitleTooltipShortRent: 'Casas en Alquiler en $displayName, $stateCode',
    },
    filter: {
        price: 'Precio',
        beds: 'Habit',
        anyPrice: 'Precio',
        minPrice: 'Mín Precio',
        maxPrice: 'Máx Precio',
        anyBeds: 'Habitaciones',
        anyBaths: 'Baño',
        type: ['Tipo de Casa', 'Tipos de Casas'],
        popertyType: 'Tipo',
        more: 'Filtros',
        filter: 'Filtrar',
        less: 'Menos',
        saveSearch: "Guardar<span class='hidden-sm'> Búsqueda</span>",
        save: 'Archivo',
        map_result_marker_zero: 'Sin Casas',
        map_result_marker_cnt_lt_total_interval: ['Mapa que muestra $pluralCount casa.', 'Mapa que muestra $pluralCount de $TOTALLISTINGCOUNT casas. Zoom o filtro para ver más.'],
        map_result_marker_cnt_eql_total_interval: ['Mapa que muestra $pluralCount casa.', 'Mapa que muestra $pluralCount de $TOTALLISTINGCOUNT casas. Mover mapa para ver más.'],
        map_result_marker_cnt_total: '$pluralCount de $TOTALLISTINGCOUNT Casas',
        unit: ['$pluralCount Unit', '$pluralCount Units'],
        homeSize: 'Tamaño de casa',
        zipCodes: 'Códigos Zip',
        neighborhoods: 'Barrios',
        cities: 'Ciudades',
        counties: 'Condados',
        searchError: 'No hay inventario basado en sus criterios de búsqueda.',
        adjustFilter: 'Ajuste los filtros para expandir su búsqueda.',
        resetFilter: 'Reinicia tus filtros',
        changeSearch: 'Cambia tu área de búsqueda',
        changeSearchArea: 'Cambiar área de búsqueda',
        expandSearchMiles: 'Ampliar búsqueda',
    },
    moreFilter: {
        sold: 'vendida',
        all: 'Todos',
        last1Week: '1 Semana',
        last1Month: '1 Mes',
        last3Months: '3 Meses',
        last6Months: '6 Meses',
        last1Year: '1 Año',
        last2Years: '2 Años',
        squareFeet: 'Superficie',
        noHOA: 'Sin HOA',
        noHOAFee: 'Sin Tarifa HOA',
        type: 'Tipo de casa',
        singleFamily: 'Chalet',
        condo: 'Casa/Apartamento',
        houses: 'Casas',
        apartments: 'Apartamentos',
        condoLite: "Casa/Aparta<span class='hidden-sm hidden-xs'>mento</span>",
        multiFamily: 'Multifamiliar',
        lotLand: 'Terreno',
        mobile: 'Casa Modular',
        other: 'Otro',
        lotSize: 'Tamaño del Terreno',
        yearBuilt: 'Año de Construcción',
        hoaFees: 'Gastos Comunes',
        maxHOAFees: 'Gastos Comunes',
        day: ['Día', 'Días'],
        daysOnMovoto: 'Días en Movoto',
        daysOn: 'Días en',
        features: ['Característica', 'Características'],
        pool: 'Piscina',
        fixerUpper: 'Necesita Reforma',
        garage: ['Garaje', 'Garajes'],
        cancel: 'Cancelar',
        reset: 'Reiniciar',
        apply: 'Aplicar',
        status: 'Estado',
        propertyStatus: 'Estado de la Propiedad',
        photos: 'Fotos',
        forSale: 'En Venta',
        recentlySold: 'Vendido Recientemente',
        foreclosure: 'Venta Corta',
        newInLast7Days: 'Nuevo en la Ultima Semana',
        openHouses: 'Casas Abiertas',
        virtualTour: 'Tour en 3D',
        newConstruction: 'Nueva Construcción',
        priceReduced: 'Precio Reducido',
        hidePendingInContract: 'Ocultar Pendiente/Contrato',
        hidePending: 'Ocultar Pendiente',
        hideContract: 'Ocultar Contrato',
        hideListingWithoutPhotos: 'Ocultar Sin Fotografías',
        showPendingOnMap: 'Mostrar pendiente en el mapa',
        listedByMovoto: 'Listado por Movoto',
        noResults: 'No hay resultados',
        propertyType: {
            singleFamily: 'Unifamiliar',
            multiFamily: 'Multifamiliar',
            condos: 'Condominios',
        },
        newListings: 'Nueva Listados',
        under: 'Menos de',
        over: 'Más de',
        acres: 'Acres',
    },
    sort: {
        sort: 'Ordenar',
        sortBy: 'Ordenar',
        sortByColon: 'Ordenar:',
        newest: 'Fecha de lista',
        relevant: 'Relevante',
        priceHigh: 'Caro',
        priceLow: 'Barato',
        sqftBig: 'Grande',
        sqftSmall: 'Pequeño',
        lotBig: 'Lot Grande',
        lotSmall: 'Lot Pequeño',
        pricePerAreaUnit: '$ / m²',
        distanceasc: 'Distancia',
        updatedTimeDesc: 'Actualizado',
    },
    card: {
        house: 'Casa',
        new: 'Nuevo',
        listed: 'Enumerado',
        open: 'Abierta',
        priceReduced: 'Precio Reducido',
        forSale: 'En Venta',
        movotoPendingListing: 'Movoto Listado Pendiente',
        pending: 'Venta Pendiente',
        activeUnderContract: 'Activo bajo contrato',
        underContract: 'Bajo Contrato',
        comingSoon: 'Próximamente',
        soldByMovoto: 'Vendido por Movoto',
        sold: 'VENDIDO',
        offMarket: 'Fuera del Mercado',
        dELISTED: 'DELISTED',
        notForSale: 'No para la venta',
        bed: 'icon-bed-es',
        bath: 'icon-bath-es',
        singleFamily: 'Chalet',
        condo: 'Condominio',
        multiFamily: 'Multifamiliar',
        lotLand: 'Terreno',
        mobile: 'Autocaravana',
        other: 'Otro',
        photo: 'Foto',
        photos: 'Fotos',
        minute_ago: ['1 Minuto', '$pluralCount Minutos'],
        hour_ago: ['1 Hora', '$pluralCount Horas'],
        day_ago: ['1 Día', '$pluralCount Días'],
        listedByMovoto: 'Listado por Movoto',
        newAgo: 'Nuevas',
        Bd: 'Cama',
        Ba: 'Baños',
        Sqft: 'Pies Cuadrados',
        Acre: 'Acre',
        zeroDay: ['1 Día', '$pluralCount Minutes'],
        forRent: 'En renta',
        condoRent: 'Departamento',
        multiFamilyRent: 'Departamento',
        providedBy: 'Proporcionado por',
    },
    advantage: {
        title: 'La ventaja de Movoto',
        movoto1:
            'Movoto le da acceso a la información más actualizada de $filterName en $nearbyCity. Como corredora autorizada en $stateFullName (y en todo Estados Unidos), Movoto tiene acceso a los datos inmobiliarios más recientes, incluidos',
        singleFamily: 'Unifamiliar en Venta',
        multiFamily: 'Multifamiliar/Dúplex en Venta',
        condo: 'Casas adosadas/condominios en venta',
        land: 'Terrenos en venta',
        mobile: 'Móvil/Fabricado para la venta',
        homesRent: 'Casas en alquiler',
        apartmentsRent: 'Apartamentos para alquilar',
        singleFamilyRent: 'Unifamiliar en alquiler',
        openHouse: 'casas abiertas',
        new: 'Nuevos listados en venta',
        sold: 'Casas vendidas recientemente',
        foreclosure: 'Ejecuciones hipotecarias en venta',
        priceReduce: 'viviendas de precio reducido',
        newConstruction: 'Nueva Construcción en Venta',
        recent: 'casas recientemente compradas',
        trends: 'tendencias del mercado',
        luxuryHomes: 'casas de lujo',
        pool: 'casas con piscina',
        backyard: 'viviendas con patio trasero',
        open_floor_plan: 'casas con un plan de piso abierto',
        walk_in_closet: 'viviendas con vestidor',
        fireplace: 'viviendas con chimenea',
        clubhouse: 'casas con casa club',
        patio: 'viviendas con patio',
        deck: 'viviendas con terraza',
        single_level: 'viviendas de un solo nivel',
        hardwood_floors: 'casas con pisos de madera',
        view: 'viviendas con vistas',
        large_kitchen: 'casas con una gran cocina',
        kitchen_island: 'homes with a kitchen island',
        porch: 'viviendas con isla de cocina',
        garage: 'viviendas con garaje',
        waterfront: 'Frente al mar en venta',
        more: 'y más en $stateFullName y más allá.',
        otherArea: '¿No ves lo que estás buscando? Expanda su búsqueda de bienes raíces a otras áreas, como',
        and: 'y',
        ready: 'Cuando esté listo para comprar, Movoto lo conectará directamente con un agente inmobiliario local con licencia que puede guiarlo en su viaje para comprar la casa de sus sueños.',
        otherParagraph:
            'Movoto le brinda acceso a la información inmobiliaria más actualizada en $stateFullName Como agente de bolsa con licencia en $stateFullName (y en todo Estados Unidos), Movoto tiene acceso a los últimos datos inmobiliarios, incluidas las casas en venta , casas recientemente compradas, nuevos listados, tendencias del mercado y más en $stateFullName y más allá. <br/> ¿No ve lo que está buscando? Expanda su búsqueda de bienes raíces a otras áreas. Cuando esté listo para comprar, Movoto lo conectará directamente con un agente inmobiliario local con licencia que puede guiarlo en su viaje para comprar la casa de sus sueños.',
    },
    compliance: {
        generic: 'La información de listado múltiple es proporcionada por ',
        start: 'La información IDX se proporciona exclusivamente para el uso personal y no comercial de los consumidores y no se puede usar para ningún otro propósito que no sea identificar propiedades potenciales que los consumidores puedan estar interesados ​​en comprar. La información se considera confiable pero no se garantiza que sea exacta. Comprador para verificar toda la informacion. La información provista es para uso personal y no comercial de los consumidores y no puede usarse para ningún otro propósito que no sea identificar propiedades potenciales que los consumidores puedan estar interesados ​​en comprar. Listado de información actualizada cada 15 minutos.',
        terms: 'Términos de Uso',
        privacy: 'Política de privacidad',
        rights: 'Todos los derechos reservados.',
        equal: 'Igualdad de oportunidades de vivienda',
        caPrivacy: 'Información de privacidad de California',
    },
    seo: {
        homes: 'Casas',
    },
    view: {
        list: 'Lista',
        map: 'Mapa',
        grid: 'Galería',
        splitView: 'División',
    },
    paging: {
        of: 'de',
        result: 'Resultado',
        result_plural: 'Resultados',
        forSale: 'En Venta',
        forRent: 'En Renta',
        sold: 'Vendida',
    },
};
